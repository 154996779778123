<template>
  <ClickOutsideDetector @clickOutside="clicked = false">
    <div
      class="buttonGroupContainer"
      @click="clicked = !clicked"
    >
      <mdb-btn
        style="top: 0; left: 0;"
        class="btn-menu buttons m-0 btn btn-outline"
        :class="{'clicked': clicked }"
        @focus="showBackground = true"
        @blur="showBackground = false"
      >
        <i class="fa fa-bars mr-2" />
        {{ mainButtonText }}
      </mdb-btn>
    
      <div
        v-for="(button, i) in buttons"
        :key="i"
        class="button-loop-button-container"
        :class="`buttonOffset-${i + 1}`"
        @mouseenter="clicked = true"
        @click="$emit(`${button.actionEmitName}`)"
      >
        <mdb-btn
          class="m-0 btn button-loop-button"
          :class="`${button.outerButtonClass}`"
        >
          <i
            :class="button.iconClass"
            class="mr-2"
          />
          {{ button.buttonText }}
        </mdb-btn>
      </div>
    </div>
  </ClickOutsideDetector>
</template>

<script>
import ClickOutsideDetector from '@/components/UI/clickOutsideDetector.vue'

export default {
  name: 'ButtonGroup',
  components: {
    ClickOutsideDetector
  },
  props: {
    buttons: {
      type: Array,
      default: () => { }
    },
    mainButtonText: {
      type: String,
      default: 'OPTIONS'
    }
  },
  data () {
    return {
      showBackground: false,
      clicked: false
    }
  }
}
</script>

<style lang="scss">
$number-of-classes: 5;

@for $i from 1 through $number-of-classes {
  $top: ($i + 0.1) * -50px;
  $transitionTime: (2 * $i + 3) * .1s;
  $shortTransitionTime: $i * 1.5 * .05s;

  .buttonOffset-#{$i} {
    scale: 0;
    top: 0;
    opacity: 0;
    transition: opacity 0.1s, top 0s 1s, scale 0.4s;
    background: var(--bg-color);
    height: 50px;
  }
  .btn-menu.clicked ~ .buttonOffset-#{$i} {
    transition: all $transitionTime cubic-bezier(0,1, .7, 1.15) !important;
    opacity: 1 !important;
    top: $top !important;
    scale: 1 !important;
  }
}
</style>

<style>
.buttonGroupContainer {
  position: relative;
  width: 100%;
}
.btn-menu, .button-loop-button-container, .button-loop-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  background: var(--bg-color);
}
</style>