<template>
  <div ref="outer" @click="handleOuterClick">
    <slot></slot>
  </div>
</template>

<script>
export default {
  mounted() {
    document.addEventListener("click", this.handleDocumentClick)
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleDocumentClick)
  },
  methods: {
    handleDocumentClick(event) {
      if (this.$refs.outer && !this.$refs.outer.contains(event.target)) {
        this.$emit("clickOutside", event)
      }
    },
    handleOuterClick(event) {
      event.stopPropagation()
    }
  }
}
</script>