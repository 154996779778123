<template>
  <div style="max-width:100%; width: 100%;height:100%;overflow-x: auto;" class="d-flex flex-column py-3">
    <LeftTopPanelTitle
      :loading="loading"
      text="My Heroes"
      icon="star"
    />
    <div
      class="mx-auto my-2 primary-colour align-items-center"
      style="text-align:center;padding-left:2rem;"
      @click="showOnlyActive = !showOnlyActive"
    >
      <mdb-input
        :value="showOnlyActive"
        type="checkbox"
        label="Active Heroes Only"
        size="sm"
      />
    </div>
    <div
      v-if="activeCardCount > 0 && !loading"
      class="primary-colour text-center d-none d-sm-block"
      style="padding-bottom:10px;"
    >
      You have {{ activeCardCount }} heroes at the cost of £{{ 5 * activeCardCount }} per month.
    </div>
    <!-- card previews -->
    <!-- MOBILE -->
    <div
      v-if="heroes.length > 0"
      style="max-height:"
      class="d-flex d-sm-none align-items-center m-auto justify-content-center"
    >
      <mdb-icon
        icon="fas fa-chevron-left fa-2x primary-colour"
        class="mr-2"
        size="lg"
        style="cursor:pointer;width:20px;"
        @click.native="decViewingHero()"
      />
      <MyHeroEditableCard
        :hero="selectedHero"
        @getHeroes="getMyHeroes()"
      />
      <mdb-icon
        icon="fas fa-chevron-right fa-2x primary-colour"
        class="ml-2"
        size="lg"
        style="cursor:pointer;width:20px;"
        @click.native="incViewingHero()"
      />
    </div>
    <!-- DESKTOP -->
    <div
      v-if="heroes.length > 0 || loading"
      id="scrollContainer"
      class="d-none d-sm-flex align-items-center"
      style="overflow: auto;width: 100%; height: 100%;padding-top:10px;"
    >
      <FadeList class="d-flex flex-nowrap">
        <div
          v-for="(hero, i) in heroes"
          :key="i"
          class="d-flex align-items-center mr-2 mr-sm-3 flex-grow-0"
        >
          <MyHeroEditableCard
            :hero="hero"
            @getHeroes="getMyHeroes()"
          />
          <MyHeroEditableCard
            v-if="i === heroes.length - 1"
            :hero="hero"
            :show-add-card="true"
          />
        </div>
      </FadeList>
      <!-- ADD ANOTHER -->
    </div>
    <div v-if="heroes.length === 0 && !loading" class="primary-colour text-center mt-5">
      YOU CURRENTLY HAVE NO ACTIVE HEROES
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userService from '@/api-services/user.service'
import MyHeroEditableCard from './myHeroEditableCard.vue'
import LeftTopPanelTitle from '@/components/leftTopPanelTitle.vue'
import FadeList from '@/components/UI/fadeList.vue'

export default {
  name: 'MyHeroes',
  components: {
    LeftTopPanelTitle, MyHeroEditableCard, FadeList
  },
  data () {
    return {
      heroes: [],
      basketId: 0,
      buttonClick: false,
      loading: false,
      showOnlyActive: true,
      selectedHeroIndex: 0
    }
  },
  computed: {
    ...mapGetters([
      'mobileView',
      'userData'
    ]),
    selectedHero () {
      return this.heroes[this.selectedHeroIndex]
    },
    activeCardCount () {
      return this.heroes.filter(h => !h.Cancelled).length
    }
  },
  watch: {
    showOnlyActive () {
      this.getMyHeroes()
    }
  },
  created () {
    this.getMyHeroes()
  },
  methods: {
    addScrollEvent () {
      const scrollContainer = document.getElementById('scrollContainer')
      scrollContainer.addEventListener("wheel", (evt) => {
        evt.preventDefault()
        scrollContainer.scrollLeft += evt.deltaY
      })
    },
    async getMyHeroes () {
      this.loading = true
      try {
        const results = await userService.getMyHeroes(this.showOnlyActive, this.userData.access_token)
        this.heroes = results.data
      } catch {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
      setTimeout(() => {
        this.addScrollEvent()
        this.loading = false
      }, 1000)
    },
    decViewingHero () {
      this.show = false
      if (this.selectedHeroIndex === 0) {
        this.selectedHeroIndex = this.heroes.length - 1
      } else { this.selectedHeroIndex = this.selectedHeroIndex - 1 }
      this.show = true
    },
    incViewingHero () {
      this.show = false
      if (this.selectedHeroIndex === this.heroes.length - 1) {
        this.selectedHeroIndex = 0
      } else { this.selectedHeroIndex = this.selectedHeroIndex + 1 }
      this.show = true
    },
  }
}
</script>
